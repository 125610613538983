import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/228364032-c46c03b0-3ad9-4114-a76b-6aabd81f72d9.png" />
    <h2>{`Usage`}</h2>
    <p>{`Pagination renders a list of page links that let users quickly navigate content that is split into sequential pages.`}</p>
    <h3>{`Best practices`}</h3>
    <ul>
      <li parentName="ul">{`Every page should have a URL.`}</li>
      <li parentName="ul">{`Paginate content that would be easier to browse when it is segmented.`}</li>
      <li parentName="ul">{`Place pagination at the end of the page content.`}</li>
      <li parentName="ul">{`If the content is only long enough for a single page, don't render pagination.`}</li>
      <li parentName="ul">{`Don't show too many or too few items per page.`}
        <ul parentName="li">
          <li parentName="ul">{`Too many items per page could be overwhelming.`}</li>
          <li parentName="ul">{`Too few items per page could be frustrating to browse.`}</li>
        </ul>
      </li>
    </ul>
    <h2>{`Anatomy`}</h2>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/228364036-bda8c774-d83c-4e54-afd6-aa1c04fac805.png" />
    <ul>
      <li parentName="ul">{`Previous page link: Always links to the page before the current page.`}</li>
      <li parentName="ul">{`Next page link: Always links to the page after the current page.`}</li>
      <li parentName="ul">{`Page numbers: Lets users skip to whatever page the number represents.`}</li>
      <li parentName="ul">{`Current page: The page the user is on right now.`}</li>
      <li parentName="ul">{`Truncation indicator: A visual hint that a chunk of page links are skipped.`}</li>
    </ul>
    <h2>{`Options`}</h2>
    <h3>{`Number of pages shown at the beginning and end of the list`}</h3>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/228364038-d73a133b-33e1-48dd-9d77-517d76fb7404.png" />
    <p>{`If it would be useful for users to be able to skip to a page near the beginning or end of the list, define how many page links should be shown at the beginning and end.`}</p>
    <h3>{`Number of pages surrounding the current page`}</h3>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/228364039-660d4513-69eb-4953-93e5-8b50dbfad559.png" />
    <p>{`You can specify how many page links before or after the current page will be shown. The first and last pages will always be shown, even if you set this value to 0.`}</p>
    <h3>{`Without page links`}</h3>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/228364040-77788a24-56c1-4926-a49a-dabf885eccb9.png" />
    <p>{`If you want to prevent users from skipping pages and force them to navigate sequentially, you may hide the page numbers and only show the previous and next page links.`}</p>
    <h2>{`Accessibility`}</h2>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="Pagination" mdxType="AccessibilityLink" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      